import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import RegenerativePledge from "../components/common/regenerativePledge";
import Seo from "../components/seo";
import Button from "../components/common/buttons/Button";
import Faqs from "../components/home/faq";

const Contactus = ({ data: { sanityContactPage, sanitySitesettings } }) => {
  let seo;
  let hero;
  let breadcrumb;
  let regenerative;
  let faq;

  sanityContactPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "regenerative") {
      regenerative = element;
    } else if (element._type === "faq") {
      faq = element;
    }
  });
  return (
    <Layout>
      <Seo data={seo} />

      <>
        <Hero heroSection={hero} h2Size="text-3xl sm:text-[60px]" h2P="pb-7" />
        <Logo logo={sanitySitesettings?.logo} />
      </>

      {sanityContactPage?.contacts && (
        <div className="tb-container mx-auto  py-5 px-[10px] mb-2">
          {sanityContactPage?.contacts.map((item, index) => (
            <div className="flex justify-center items-center show">
              <div className="w-[300px] md:w-[560px]">
                <Button
                  key={index}
                  btnText={item?.title}
                  btnLink={item?.link}
                  btnType={item?.variant}
                  linkType={item?.type}
                  formId={item?.formId}
                  downloadLink={item?.downloadLink}
                  height="h-[50px]"
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {faq?.heading && faq?.faqList.length > 0 && <Faqs data={faq} />}
      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      <Instagram />
      {regenerative && (
        <>
          <RegenerativePledge data={regenerative} />
          <Logo logo={sanitySitesettings?.logo} />
        </>
      )}
    </Layout>
  );
};

export default Contactus;

export const query = graphql`
  query {
    sanityContactPage {
      pageBuilder {
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityFaq {
          _type
          heading
          faqList {
            question
            _rawAnswer
          }
        }
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          alignment
          ctaButton {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityRegenerative {
          _type
          heading
          _rawContent
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
        }
      }

      contacts {
        title
        link
        type
        variant
        formId
        downloadLink
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
